const municipalities = [
  { value: '', label: 'Select...' },
  { value: 'Abington', label: 'Abington' },
  { value: 'Acton', label: 'Acton' },
  { value: 'Acushnet', label: 'Acushnet' },
  { value: 'Adams', label: 'Adams' },
  { value: 'Agawam', label: 'Agawam' },
  { value: 'Alford', label: 'Alford' },
  { value: 'Amesbury', label: 'Amesbury' },
  { value: 'Amherst', label: 'Amherst' },
  { value: 'Andover', label: 'Andover' },
  { value: 'Arlington', label: 'Arlington' },
  { value: 'Ashburnham', label: 'Ashburnham' },
  { value: 'Ashby', label: 'Ashby' },
  { value: 'Ashfield', label: 'Ashfield' },
  { value: 'Ashland', label: 'Ashland' },
  { value: 'Athol', label: 'Athol' },
  { value: 'Attleboro', label: 'Attleboro' },
  { value: 'Auburn', label: 'Auburn' },
  { value: 'Avon', label: 'Avon' },
  { value: 'Ayer', label: 'Ayer' },
  { value: 'Barnstable', label: 'Barnstable' },
  { value: 'Barre', label: 'Barre' },
  { value: 'Becket', label: 'Becket' },
  { value: 'Bedford', label: 'Bedford' },
  { value: 'Belchertown', label: 'Belchertown' },
  { value: 'Bellingham', label: 'Bellingham' },
  { value: 'Belmont', label: 'Belmont' },
  { value: 'Berkley', label: 'Berkley' },
  { value: 'Berlin', label: 'Berlin' },
  { value: 'Bernardston', label: 'Bernardston' },
  { value: 'Beverly', label: 'Beverly' },
  { value: 'Billerica', label: 'Billerica' },
  { value: 'Blackstone', label: 'Blackstone' },
  { value: 'Blandford', label: 'Blandford' },
  { value: 'Bolton', label: 'Bolton' },
  { value: 'Boston', label: 'Boston' },
  { value: 'Bourne', label: 'Bourne' },
  { value: 'Boxborough', label: 'Boxborough' },
  { value: 'Boxford', label: 'Boxford' },
  { value: 'Boylston', label: 'Boylston' },
  { value: 'Braintree', label: 'Braintree' },
  { value: 'Brewster', label: 'Brewster' },
  { value: 'Bridgewater', label: 'Bridgewater' },
  { value: 'Brimfield', label: 'Brimfield' },
  { value: 'Brockton', label: 'Brockton' },
  { value: 'Brookfield', label: 'Brookfield' },
  { value: 'Brookline', label: 'Brookline' },
  { value: 'Buckland', label: 'Buckland' },
  { value: 'Burlington', label: 'Burlington' },
  { value: 'Cambridge', label: 'Cambridge' },
  { value: 'Canton', label: 'Canton' },
  { value: 'Carlisle', label: 'Carlisle' },
  { value: 'Carver', label: 'Carver' },
  { value: 'Charlemont', label: 'Charlemont' },
  { value: 'Charlton', label: 'Charlton' },
  { value: 'Chatham', label: 'Chatham' },
  { value: 'Chelmsford', label: 'Chelmsford' },
  { value: 'Chelsea', label: 'Chelsea' },
  { value: 'Cheshire', label: 'Cheshire' },
  { value: 'Chester', label: 'Chester' },
  { value: 'Chesterfield', label: 'Chesterfield' },
  { value: 'Chicopee', label: 'Chicopee' },
  { value: 'Chilmark', label: 'Chilmark' },
  { value: 'Clarksburg', label: 'Clarksburg' },
  { value: 'Clinton', label: 'Clinton' },
  { value: 'Cohasset', label: 'Cohasset' },
  { value: 'Colrain', label: 'Colrain' },
  { value: 'Concord', label: 'Concord' },
  { value: 'Conway', label: 'Conway' },
  { value: 'Cummington', label: 'Cummington' },
  { value: 'Dalton', label: 'Dalton' },
  { value: 'Danvers', label: 'Danvers' },
  { value: 'Dartmouth', label: 'Dartmouth' },
  { value: 'Dedham', label: 'Dedham' },
  { value: 'Deerfield', label: 'Deerfield' },
  { value: 'Dennis', label: 'Dennis' },
  { value: 'Dighton', label: 'Dighton' },
  { value: 'Douglas', label: 'Douglas' },
  { value: 'Dover', label: 'Dover' },
  { value: 'Dracut', label: 'Dracut' },
  { value: 'Dudley', label: 'Dudley' },
  { value: 'Dunstable', label: 'Dunstable' },
  { value: 'Duxbury', label: 'Duxbury' },
  { value: 'East Bridgewater', label: 'East Bridgewater' },
  { value: 'East Brookfield', label: 'East Brookfield' },
  { value: 'East Longmeadow', label: 'East Longmeadow' },
  { value: 'Eastham', label: 'Eastham' },
  { value: 'Easthampton', label: 'Easthampton' },
  { value: 'Easton', label: 'Easton' },
  { value: 'Edgartown', label: 'Edgartown' },
  { value: 'Egremont', label: 'Egremont' },
  { value: 'Erving', label: 'Erving' },
  { value: 'Essex', label: 'Essex' },
  { value: 'Everett', label: 'Everett' },
  { value: 'Fairhaven', label: 'Fairhaven' },
  { value: 'Fall River', label: 'Fall River' },
  { value: 'Falmouth', label: 'Falmouth' },
  { value: 'Fitchburg', label: 'Fitchburg' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Foxborough', label: 'Foxborough' },
  { value: 'Framingham', label: 'Framingham' },
  { value: 'Franklin', label: 'Franklin' },
  { value: 'Freetown', label: 'Freetown' },
  { value: 'Gardner', label: 'Gardner' },
  { value: 'Aquinnah *', label: 'Aquinnah *' },
  { value: 'Georgetown', label: 'Georgetown' },
  { value: 'Gill', label: 'Gill' },
  { value: 'Gloucester', label: 'Gloucester' },
  { value: 'Goshen', label: 'Goshen' },
  { value: 'Gosnold', label: 'Gosnold' },
  { value: 'Grafton', label: 'Grafton' },
  { value: 'Granby', label: 'Granby' },
  { value: 'Granville', label: 'Granville' },
  { value: 'Great Barrington', label: 'Great Barrington' },
  { value: 'Greenfield', label: 'Greenfield' },
  { value: 'Groton', label: 'Groton' },
  { value: 'Groveland', label: 'Groveland' },
  { value: 'Hadley', label: 'Hadley' },
  { value: 'Halifax', label: 'Halifax' },
  { value: 'Hamilton', label: 'Hamilton' },
  { value: 'Hampden', label: 'Hampden' },
  { value: 'Hancock', label: 'Hancock' },
  { value: 'Hanover', label: 'Hanover' },
  { value: 'Hanson', label: 'Hanson' },
  { value: 'Hardwick', label: 'Hardwick' },
  { value: 'Harvard', label: 'Harvard' },
  { value: 'Harwich', label: 'Harwich' },
  { value: 'Hatfield', label: 'Hatfield' },
  { value: 'Haverhill', label: 'Haverhill' },
  { value: 'Hawley', label: 'Hawley' },
  { value: 'Heath', label: 'Heath' },
  { value: 'Hingham', label: 'Hingham' },
  { value: 'Hinsdale', label: 'Hinsdale' },
  { value: 'Holbrook', label: 'Holbrook' },
  { value: 'Holden', label: 'Holden' },
  { value: 'Holland', label: 'Holland' },
  { value: 'Holliston', label: 'Holliston' },
  { value: 'Holyoke', label: 'Holyoke' },
  { value: 'Hopedale', label: 'Hopedale' },
  { value: 'Hopkinton', label: 'Hopkinton' },
  { value: 'Hubbardston', label: 'Hubbardston' },
  { value: 'Hudson', label: 'Hudson' },
  { value: 'Hull', label: 'Hull' },
  { value: 'Huntington', label: 'Huntington' },
  { value: 'Ipswich', label: 'Ipswich' },
  { value: 'Kingston', label: 'Kingston' },
  { value: 'Lakeville', label: 'Lakeville' },
  { value: 'Lancaster', label: 'Lancaster' },
  { value: 'Lanesborough', label: 'Lanesborough' },
  { value: 'Lawrence', label: 'Lawrence' },
  { value: 'Lee', label: 'Lee' },
  { value: 'Leicester', label: 'Leicester' },
  { value: 'Lenox', label: 'Lenox' },
  { value: 'Leominster', label: 'Leominster' },
  { value: 'Leverett', label: 'Leverett' },
  { value: 'Lexington', label: 'Lexington' },
  { value: 'Leyden', label: 'Leyden' },
  { value: 'Lincoln', label: 'Lincoln' },
  { value: 'Littleton', label: 'Littleton' },
  { value: 'Longmeadow', label: 'Longmeadow' },
  { value: 'Lowell', label: 'Lowell' },
  { value: 'Ludlow', label: 'Ludlow' },
  { value: 'Lunenburg', label: 'Lunenburg' },
  { value: 'Lynn', label: 'Lynn' },
  { value: 'Lynnfield', label: 'Lynnfield' },
  { value: 'Malden', label: 'Malden' },
  { value: 'Manchester **', label: 'Manchester **' },
  { value: 'Mansfield', label: 'Mansfield' },
  { value: 'Marblehead', label: 'Marblehead' },
  { value: 'Marion', label: 'Marion' },
  { value: 'Marlborough', label: 'Marlborough' },
  { value: 'Marshfield', label: 'Marshfield' },
  { value: 'Mashpee', label: 'Mashpee' },
  { value: 'Mattapoisett', label: 'Mattapoisett' },
  { value: 'Maynard', label: 'Maynard' },
  { value: 'Medfield', label: 'Medfield' },
  { value: 'Medford', label: 'Medford' },
  { value: 'Medway', label: 'Medway' },
  { value: 'Melrose', label: 'Melrose' },
  { value: 'Mendon', label: 'Mendon' },
  { value: 'Merrimac', label: 'Merrimac' },
  { value: 'Methuen', label: 'Methuen' },
  { value: 'Middleborough', label: 'Middleborough' },
  { value: 'Middlefield', label: 'Middlefield' },
  { value: 'Middleton', label: 'Middleton' },
  { value: 'Milford', label: 'Milford' },
  { value: 'Millbury', label: 'Millbury' },
  { value: 'Millis', label: 'Millis' },
  { value: 'Millville', label: 'Millville' },
  { value: 'Milton', label: 'Milton' },
  { value: 'Monroe', label: 'Monroe' },
  { value: 'Monson', label: 'Monson' },
  { value: 'Montague', label: 'Montague' },
  { value: 'Monterey', label: 'Monterey' },
  { value: 'Montgomery', label: 'Montgomery' },
  { value: 'Mount Washington', label: 'Mount Washington' },
  { value: 'Nahant', label: 'Nahant' },
  { value: 'Nantucket', label: 'Nantucket' },
  { value: 'Natick', label: 'Natick' },
  { value: 'Needham', label: 'Needham' },
  { value: 'New Ashford', label: 'New Ashford' },
  { value: 'New Bedford', label: 'New Bedford' },
  { value: 'New Braintree', label: 'New Braintree' },
  { value: 'New Marlborough', label: 'New Marlborough' },
  { value: 'New Salem', label: 'New Salem' },
  { value: 'Newbury', label: 'Newbury' },
  { value: 'Newburyport', label: 'Newburyport' },
  { value: 'Newton', label: 'Newton' },
  { value: 'Norfolk', label: 'Norfolk' },
  { value: 'North Adams', label: 'North Adams' },
  { value: 'North Andover', label: 'North Andover' },
  { value: 'North Attleborough', label: 'North Attleborough' },
  { value: 'North Brookfield', label: 'North Brookfield' },
  { value: 'North Reading', label: 'North Reading' },
  { value: 'Northampton', label: 'Northampton' },
  { value: 'Northborough', label: 'Northborough' },
  { value: 'Northbridge', label: 'Northbridge' },
  { value: 'Northfield', label: 'Northfield' },
  { value: 'Norton', label: 'Norton' },
  { value: 'Norwell', label: 'Norwell' },
  { value: 'Norwood', label: 'Norwood' },
  { value: 'Oak bluffs', label: 'Oak Bluffs' },
  { value: 'Oakham', label: 'Oakham' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Orleans', label: 'Orleans' },
  { value: 'Otis', label: 'Otis' },
  { value: 'Oxford', label: 'Oxford' },
  { value: 'Palmer', label: 'Palmer' },
  { value: 'Paxton', label: 'Paxton' },
  { value: 'Peabody', label: 'Peabody' },
  { value: 'Pelham', label: 'Pelham' },
  { value: 'Pembroke', label: 'Pembroke' },
  { value: 'Pepperell', label: 'Pepperell' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Petersham', label: 'Petersham' },
  { value: 'Phillipston', label: 'Phillipston' },
  { value: 'Pittsfield', label: 'Pittsfield' },
  { value: 'Plainfield', label: 'Plainfield' },
  { value: 'Plainville', label: 'Plainville' },
  { value: 'Plymouth', label: 'Plymouth' },
  { value: 'Plympton', label: 'Plympton' },
  { value: 'Princeton', label: 'Princeton' },
  { value: 'Provincetown', label: 'Provincetown' },
  { value: 'Quincy', label: 'Quincy' },
  { value: 'Randolph', label: 'Randolph' },
  { value: 'Raynham', label: 'Raynham' },
  { value: 'Reading', label: 'Reading' },
  { value: 'Rehoboth', label: 'Rehoboth' },
  { value: 'Revere', label: 'Revere' },
  { value: 'Richmond', label: 'Richmond' },
  { value: 'Rochester', label: 'Rochester' },
  { value: 'Rockland', label: 'Rockland' },
  { value: 'Rockport', label: 'Rockport' },
  { value: 'Rowe', label: 'Rowe' },
  { value: 'Rowley', label: 'Rowley' },
  { value: 'Royalston', label: 'Royalston' },
  { value: 'Russell', label: 'Russell' },
  { value: 'Rutland', label: 'Rutland' },
  { value: 'Salem', label: 'Salem' },
  { value: 'Salisbury', label: 'Salisbury' },
  { value: 'Sandisfield', label: 'Sandisfield' },
  { value: 'Sandwich', label: 'Sandwich' },
  { value: 'Saugus', label: 'Saugus' },
  { value: 'Savoy', label: 'Savoy' },
  { value: 'Scituate', label: 'Scituate' },
  { value: 'Seekonk', label: 'Seekonk' },
  { value: 'Sharon', label: 'Sharon' },
  { value: 'Sheffield', label: 'Sheffield' },
  { value: 'Shelburne', label: 'Shelburne' },
  { value: 'Sherborn', label: 'Sherborn' },
  { value: 'Shirley', label: 'Shirley' },
  { value: 'Shrewsbury', label: 'Shrewsbury' },
  { value: 'Shutesbury', label: 'Shutesbury' },
  { value: 'Somerset', label: 'Somerset' },
  { value: 'Somerville', label: 'Somerville' },
  { value: 'South hadley', label: 'South Hadley' },
  { value: 'Southampton', label: 'Southampton' },
  { value: 'Southborough', label: 'Southborough' },
  { value: 'Southbridge', label: 'Southbridge' },
  { value: 'Southwick', label: 'Southwick' },
  { value: 'Spencer', label: 'Spencer' },
  { value: 'Springfield', label: 'Springfield' },
  { value: 'Sterling', label: 'Sterling' },
  { value: 'Stockbridge', label: 'Stockbridge' },
  { value: 'Stoneham', label: 'Stoneham' },
  { value: 'Stoughton', label: 'Stoughton' },
  { value: 'Stow', label: 'Stow' },
  { value: 'Sturbridge', label: 'Sturbridge' },
  { value: 'Sudbury', label: 'Sudbury' },
  { value: 'Sunderland', label: 'Sunderland' },
  { value: 'Sutton', label: 'Sutton' },
  { value: 'Swampscott', label: 'Swampscott' },
  { value: 'Swansea', label: 'Swansea' },
  { value: 'Taunton', label: 'Taunton' },
  { value: 'Templeton', label: 'Templeton' },
  { value: 'Tewksbury', label: 'Tewksbury' },
  { value: 'Tisbury', label: 'Tisbury' },
  { value: 'Tolland', label: 'Tolland' },
  { value: 'Topsfield', label: 'Topsfield' },
  { value: 'Townsend', label: 'Townsend' },
  { value: 'Truro', label: 'Truro' },
  { value: 'Tyngsborough', label: 'Tyngsborough' },
  { value: 'Tyringham', label: 'Tyringham' },
  { value: 'Upton', label: 'Upton' },
  { value: 'Uxbridge', label: 'Uxbridge' },
  { value: 'Wakefield', label: 'Wakefield' },
  { value: 'Wales', label: 'Wales' },
  { value: 'Walpole', label: 'Walpole' },
  { value: 'Waltham', label: 'Waltham' },
  { value: 'Ware', label: 'Ware' },
  { value: 'Wareham', label: 'Wareham' },
  { value: 'Warren', label: 'Warren' },
  { value: 'Warwick', label: 'Warwick' },
  { value: 'Washington', label: 'Washington' },
  { value: 'Watertown', label: 'Watertown' },
  { value: 'Wayland', label: 'Wayland' },
  { value: 'Webster', label: 'Webster' },
  { value: 'Wellesley', label: 'Wellesley' },
  { value: 'Wellfleet', label: 'Wellfleet' },
  { value: 'Wendell', label: 'Wendell' },
  { value: 'Wenham', label: 'Wenham' },
  { value: 'West Boylston', label: 'West Boylston' },
  { value: 'West Bridgewater', label: 'West Bridgewater' },
  { value: 'West Brookfield', label: 'West Brookfield' },
  { value: 'West Newbury', label: 'West Newbury' },
  { value: 'West Springfield', label: 'West Springfield' },
  { value: 'West Stockbridge', label: 'West Stockbridge' },
  { value: 'West Tisbury', label: 'West Tisbury' },
  { value: 'Westborough', label: 'Westborough' },
  { value: 'Westfield', label: 'Westfield' },
  { value: 'Westford', label: 'Westford' },
  { value: 'Westhampton', label: 'Westhampton' },
  { value: 'Westminster', label: 'Westminster' },
  { value: 'Weston', label: 'Weston' },
  { value: 'Westport', label: 'Westport' },
  { value: 'Westwood', label: 'Westwood' },
  { value: 'Weymouth', label: 'Weymouth' },
  { value: 'Whately', label: 'Whately' },
  { value: 'Whitman', label: 'Whitman' },
  { value: 'Wilbraham', label: 'Wilbraham' },
  { value: 'Williamsburg', label: 'Williamsburg' },
  { value: 'Williamstown', label: 'Williamstown' },
  { value: 'Wilmington', label: 'Wilmington' },
  { value: 'Winchendon', label: 'Winchendon' },
  { value: 'Winchester', label: 'Winchester' },
  { value: 'Windsor', label: 'Windsor' },
  { value: 'Winthrop', label: 'Winthrop' },
  { value: 'Woburn', label: 'Woburn' },
  { value: 'Worcester', label: 'Worcester' },
  { value: 'Worthington', label: 'Worthington' },
  { value: 'Wrentham', label: 'Wrentham' },
  { value: 'Yarmouth', label: 'Yarmouth' }
]

module.exports = { municipalities };